import React from "react";
import styled from "styled-components";
import moment from "moment";
import LogoImage from "../../assets/logo2.png";
// import LogoImageSmall from "../../assets/logo2_1_300x60.png";
// import LogoImageSmallest from "../../assets/logo2_150x30.png";
import { Link, useNavigate } from "react-router-dom";

const Card = styled.article`
  @media only screen and (min-width: 768px) {
    grid-area: Post;
  }
  font-size: 2rem;
  /* grid-area: PostCard; */
  padding: 2rem;
  margin: 1.25rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  align-items: flex-start;
  background: #fff;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  width: 50rem;
`;
const CardMeataHeader = styled.div``;
const CardMedia = styled.div`
  position: relative;
  /* width: 20rem;
  height: 10rem; */
  width: 100%;
  align-self: center;
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;
const CardContent = styled.div`
  /* color: #999999; */
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.5rem;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
`;
const CardExcerpt = styled.p`
  transition: max-height 0.3s ease-in-out;
`;
const H2 = styled.h2``;
const Span = styled.span`
  /* border-right: 1px solid #4d4d4d; */
  color: #0EA5E9;
  margin-right: 1.5px;
  margin-left: 1.5px;
  font-size: 12px;
  
`;
const SpanCategory = styled.span`
  background: #e74c3c;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 0.2rem 0.9rem;
  display: inline-block;
  letter-spacing: 0.06rem;
  font-size: 2rem;
  font-weight: bold;
  left: 0;
  bottom: 0;
  position: absolute;
`;
const CardFooter = styled.footer`
  color: #ccc;
  font-size: 1.8rem;
  text-align: center;
  display: flex;
  align-items: flex-start;
  
`;
const CardButton = styled.button`
  color: #1f5dfe;
  background: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
`;



const Postcard = ({post}) => {
  console.log('posts from postcard', post.node.coverImage)
  const navigate = useNavigate();
  return (
    <>
    {
      post && post.node.coverImage ? (
        <Card>
      <CardMeataHeader>
      <Link to={`/blog/${post.node.slug}`}> <H2>{post.node.title}</H2></Link>
       
        <Span>Société | {moment(post.node.createdAt).format("MMM DD, YYYY")}
</Span>
      </CardMeataHeader>
      <CardMedia>
        <img src={post.node.coverImage.url} alt="Post Image" />
        <SpanCategory>Société</SpanCategory>
      </CardMedia>
      <CardContent>
        <CardExcerpt>
          {/* Comme beaucoup d'enfants ayant grandi au sein d'une famille, je
          bénéficiais d'une parfaite attention de la part de ma mère, de mon
          père aussi (bien qu'il ne vivait pas sous le même toît que nous). J'ai
          reçu une éducation familliale très stricte. */}
        {post.node.excerpt}
        </CardExcerpt>

        <CardFooter role="contentinfo">
          <Span> <em>{post.node.author.name}</em> </Span> 
        </CardFooter>
        <CardButton onClick={() => navigate("/blog/" + post.node.slug)}>Continuer la Lecture &rarr;</CardButton>
      </CardContent>
    </Card>
      ): (
        <h1>No Image</h1>
      )
    }
    </>
    
  );
};

export default Postcard;