import React from "react";

import * as FaIcons from "react-icons/fa";
import {
  ActionButtonsContainer,
  ArrowNavigation,
  CardTestimonialContainer,
  LeftArrow,
  QuoteTop,
  RightArrow,
  SectionSubheader,
  StyledTestimonial,
  StyledTestimonialDescription,
} from "./Home.styled";
import CardTestimonial from "./CardTestimonial";
import { testimonialContent } from "./content";

const Testimonial = () => {
  return (
    <>
      <StyledTestimonial>
        <StyledTestimonialDescription>
          <SectionSubheader>déclarations d'amour</SectionSubheader>
          <QuoteTop>
            <FaIcons.FaQuoteLeft />
          </QuoteTop>

          <h1>Ce que nos membres ont à dire:</h1>
          <p>Vous aussi, vous pouvez faire partie de ce changement.</p>

          <ActionButtonsContainer>
            <span>devenez un bénévole</span>
          </ActionButtonsContainer>
        </StyledTestimonialDescription>
        <CardTestimonialContainer>
          <LeftArrow />
          {testimonialContent.map((item, index) => (
            <CardTestimonial key={index} item={item} />
          ))}
          <RightArrow />
        </CardTestimonialContainer>
      </StyledTestimonial>
    </>
  );
};

export default Testimonial;
