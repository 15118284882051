import parse from "html-react-parser";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { getComments } from "../../services";
import CommentsForm from "./CommentsForm";

const Comments = ({ slug }) => {
  const [comments, setComments] = useState([]);

  console.log("list of comments", comments);
  useEffect(() => {
    getComments(slug).then((result) => {
      setComments(result);
    });
  }, []);
  return (
    <>
      {comments.length > 0 ? (
        <>
          <h3> {comments.length}Comments</h3>
          {comments.map((comment, index) => (
            <div key={index}>
              <p>
                <span>{comment.name}</span>
                on
                {moment(comment.createdAt).format("MMM DD, YYYY")}
              </p>

              <p>{parse(comment.comment)}</p>
            </div>
          ))}
        </>
      ) : (
        <div>
          <h4>No Comments</h4>
        </div>
      )}
      <CommentsForm slug={slug} />
    </>
  );
};

export default Comments;