import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";

import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import { ReactComponent as MessengerIcon } from "../../assets/icons/messenger.svg";
import { ReactComponent as CaretIcon } from "../../assets/icons/caret.svg";

import { ReactComponent as CogIcon } from "../../assets/icons/cog.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";

import styled from "styled-components";
import logo from "../../assets/logo.png";
import userPhoto from "../../assets/user-1.jpeg";
import Searchbar from "../Searchbar/SearchBar";
import { useRef } from "react";
import breakpoint from "../../styles/BreakPoint";

const HeaderDashboard = styled.header`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0eded;
  height: 7rem;
  font-size: 1.4rem;
`;
const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  align-self: stretch;

  padding: 0 2rem;
  cursor: pointer;
`;
const HeaderUserPhoto = styled.div`
  padding: 0 2rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
`;
const HeaderNotification = styled(HeaderUserPhoto)``;
const HeaderTelegram = styled(HeaderUserPhoto)``;
const Logo = styled.img`
  height: 3.25rem;
  margin-left: 2rem;
`;
const UserPhoto = styled.img`
  height: 3.75rem;
  /* width: calc(3.75rem * 0.5);
  height: calc(3.75rem * 0.5); */
  width: 3.75rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  padding-right: 0.1rem;
`;

const NavbarWrapper = styled.nav`
  height: 3.75rem;
  /* background-color: #0c1218; */
  padding: 0 1rem;
`;
const NavbarNav = styled.ul`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
  & a {
    color: #dadce1;
    text-decoration: none;
  }
`;

const NavItemWrapper = styled.li`
  width: calc(3.75rem * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconButton = styled.a`
  width: calc(3.75rem * 0.5);
  height: calc(3.75rem * 0.5);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  &:hover {
    filter: brightness(1.2);
  }
  & svg {
    fill: #dadce1;
    width: 20px;
    height: 20px;
  }
`;
const MenuItem = styled.a`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: red;
  transition: background 500ms;
  padding: 0.5rem;
  margin-right: 0.5rem;
  &:hover {
    background-color: #525357;
    
  }
`;
const IconButtonwrapper = styled.span`
  width: calc(3.75rem * 0.5);
  height: calc(3.75rem * 0.5);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  & svg {
    fill: #dadce1;
    width: 20px;
    height: 20px;
  }

  margin-right: 0.5rem;
  &:hover {
    filter: brightness(1.2);
  }
`;
const IconRight = styled.span`
  margin-left: auto;
`;
const DropDownWrapper = styled.div`
  position: absolute;
  top: 16rem;
  width: 16rem;
  transform: translateX(-45%);
  background-color: #0ea5e9;
  border: 1px solid #474a4d;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;

  z-index: 20;
  @media only screen and ${breakpoint.device.bpLargest} {
    top: 7.5rem;
    width: 16rem;
  }
`;
const MenuWrapper = styled.div`
  width: 100%;
 
`;
const DropdownMenu = () => {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }
  function DropdownItem(props) {
    return (
      <MenuItem onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        <IconButtonwrapper>{props.leftIcon}</IconButtonwrapper>
        {props.children}
        <IconRight>{props.rightIcon}</IconRight>
      </MenuItem>
    );
  }

  return (
    <DropDownWrapper style={{ height: menuHeight }} ref={dropdownRef}>
      <MenuWrapper>
        <DropdownItem
          leftIcon={<UserPhoto src={userPhoto} alt="User Photo" />}
          goToMenu="Karl"
        >
          Karl Angrand
        </DropdownItem>
        <DropdownItem>My Profile</DropdownItem>
        <DropdownItem
          leftIcon={<CogIcon />}
          rightIcon={<ChevronIcon />}
          goToMenu="settings"
        >
          Settings
        </DropdownItem>
        <DropdownItem
          leftIcon="🦧"
          rightIcon={<ChevronIcon />}
          goToMenu="animals"
        >
          Animals
        </DropdownItem>
      </MenuWrapper>
    </DropDownWrapper>
  );
};
const NavItem = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <NavItemWrapper>
      <IconButton onClick={() => setOpen(!open)}>{props.icon}</IconButton>

      {open && props.children}
    </NavItemWrapper>
  );
};
const Navbar = (props) => {
  return (
    <NavbarWrapper>
      <NavbarNav>{props.children}</NavbarNav>
    </NavbarWrapper>
  );
};
const MemberHeaderNav = () => {
  return (
    <HeaderDashboard>
      <Logo src={logo} alt="Logo" />
      <Searchbar />
      <HeaderNav>
        {/* <HeaderTelegram>
          <FaTelegramPlane />
          <span>4</span>
        </HeaderTelegram>
        <HeaderNotification>
          <FaBookmark />
          <span>1</span>
        </HeaderNotification> */}

        <HeaderUserPhoto>
          <UserPhoto src={userPhoto} alt="User Photo" />
          {/* <span>Karl</span> */}
          <Navbar>
            <NavItem icon={<PlusIcon />} />
            <NavItem icon={<BellIcon />} />
            <NavItem icon={<MessengerIcon />} />

            <NavItem icon={<CaretIcon />}>
              <DropdownMenu></DropdownMenu>
            </NavItem>
          </Navbar>
        </HeaderUserPhoto>
      </HeaderNav>
    </HeaderDashboard>
  );
};

export default MemberHeaderNav;
