import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import Home from "./pages/Home";
import BlogLayout from "./layouts/BlogLayout";
import Blog from "./pages/Blog";
import PostHome from "./components/Posts/PostHome";
import Postdetail from "./components/Posts/PostDetail";
import Dashboard from "./Container/Dashboard";
import MemberLayout from "./layouts/MemberLayout";
import Dashboadhome from "./components/Dashboard/DashboadHome";
import Document from "./components/Document/Document";
import Settings from "./components/Settings/Settings";
import Decouvrir from "./pages/decouvrir";
import Donation from "./pages/donation";
import Activities from "./pages/activities";
import Signin from "./pages/signin";
import GlobalStyles from "./styles/Global";
import { Flex } from "./components/Flex/Flex.styled";
function App() {
  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/accueil" element={<Home />} />
          <Route path="/découvrir" element={<Decouvrir />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/activites" element={<Activities />} />
          <Route path="/connecter" element={<Signin />} />
        </Route>
        <Route element={<BlogLayout />}>
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postId" element={<Postdetail />} />
        </Route>
        <Route path="member" element={<MemberLayout />}>
          <Route path="" element={<Dashboadhome />} />
          <Route path="dashboard" element={<Dashboadhome />} />
          <Route path="document" element={<Document />} />
          <Route path="setting" element={<Settings />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
function NoMatch() {
  return (
    <>
      <Flex>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </Flex>
    </>
  );
}

export default App;
