import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import device from "../../styles/BreakPoint";

export const HomeContainer = styled.div`
  /* display: grid; */
  /* grid-template-rows: 85vh min-content 40vw repeat(4, min-content); */
  /* grid-template-columns: repeat(8, minmax(min-content, 14rem)); */
`;

//HERO SECTION

export const StyledHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85vh;
  width: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.5)
    ),
    url();
  background-size: cover;
  background-position: top;
  position: relative;
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

export const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #434343;
`;

export const HeroContainer = styled.div`
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10rem;
  max-width: 120rem;
  margin: 0 10rem;
`;

export const HeroTextBox = styled.div`
  grid-column: 1/3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* text-align: center; */
  flex-direction: column;
  flex: 1;
  /* margin-top: 20rem; */
  z-index: 2;
  color: #fff;
  /* max-width: 120rem; */
  flex-grow: 3;
`;

export const PrimaryHeading = styled.h1`
  font-weight: 700;
  font-size: 6rem;
  line-height: 6rem;
  letter-spacing: 0.015em;
  /* font-size: 4.6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.5px;*/
  margin-bottom: 2.4rem;
  margin-top: 2rem;
  text-transform: uppercase;

  span {
    font-weight: 700;
    color: #0ea5e9;
    letter-spacing: 0.2rem;
  }
  span:nth-child(1) {
    color: #f1cb00;
  }

  @media ${device.laptop} {
    font-size: 4rem;
  }
`;

export const SecondaryHeading = styled.p`
  margin-bottom: 10rem;
  font-size: 1.6rem;
  /* line-height: 1.6; */
  /* margin-bottom: 1.2rem; */
  font-style: normal;
  font-weight: 400;
  /* font-size: 2.2rem; */

  /* or 191% */

  letter-spacing: 0.0625em;

  @media only screen and(max-width:75em) {
  }
`;

export const Cta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  svg {
    color: #fff;
    width: 35px;
    height: 35px;
  }
`;

export const HeroRight = styled.div`
  display: grid;
  padding: 4rem;
  padding-bottom: 0;
  /* width: 39rem;
  height: 39rem; */
  flex: 1;
  background: rgba(196, 196, 196, 0.29);
  backdrop-filter: blur(21px);
  flex-grow: 1;

  p {
    font-size: 1.8rem;
    color: #fff;
    span {
      text-decoration: none;
      color: #0ea5e9;
    }
  }

  span {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #f1cb00;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;

  button {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    width: 12.5rem;
    height: 3.9rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  button:nth-child(1) {
    background: #f1cb00;
  }
`;

//ABOUT SECTION

export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;
  position: relative;
  background: var(--color-grey-light-1);
  /* background-size: cover; */
  /* margin: 0 auto; */

  h2 {
    align-self: flex-start;
    font-size: 3.2rem;
    color: #212529;
    margin-left: 11.5rem;
    margin-bottom: 5rem;
  }
`;

export const StyledAboutBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 120rem;
  overflow: hidden;
  z-index: -1;
`;

export const SectionSubheader = styled.span`
  text-transform: uppercase;
  align-self: flex-start;
  margin-left: 10rem;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  display: flex;
  /* grid-template-columns: 1fr max-content;*/
  gap: 1.5rem;
  align-items: center;
  font-weight: 500;

  &::before {
    content: "";
    height: 18px;
    width: 3.5px;
    background-color: #fe5000;
    display: block;
  }
`;

export const CircleContainer = styled.div`
  div {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fe5000;
  }
  div:nth-child(1) {
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
`;

export const CardAboutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  max-width: 120rem;
`;

export const StyledCardAbout = styled.div`
  /* display: grid;
  grid-template-columns: min-content 1fr; */
  /* grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem; */

  display: flex;
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* background: rgb(0, 0, 0, 0.4); */
  background: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(183, 228, 248, 0.5);
  padding: 4rem 2rem;
  gap: 1.8rem;
  flex-direction: column;
  max-width: 25rem;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
    z-index: 5;
  }

  svg {
    color: var(--color-primary);
    width: 35px;
    height: 35px;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
    color: var(--color-grey-dark-4);
  }
  p {
    font-size: 1.6rem;
    color: var(--color-grey-dark-4);
  }
  div:nth-child(3) {
    border-top: solid 0.5px var(--color-grey-light-4);
    padding: 0.5rem 0;
  }
  div:nth-child(1) {
    margin-top: -1.8rem;
  }
`;

//ACTIVITY SECTION

export const ActivitySection = styled.div`
  padding: 5rem 0;
  background: #b7e4f8;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    94.59deg,
    rgba(254, 31, 111, 0.1) 1.23%,
    rgba(14, 165, 233, 0.17) 96.92%
  );
  /* z-index: -2; */
`;

export const ActivityHeader = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 3.2rem;
    color: #212529;
    margin-left: 11.5rem;
  }
`;

export const ActivityTabs = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  gap: 1.3rem;
  padding: 1.2rem;
  margin-bottom: 4rem;

  span {
    padding: 0.8rem 6rem;
    background: #fff;
    box-shadow: 0px 0px 8px #1f5dfe;
    font-weight: 500;
    font-size: 1.6rem;
    transition: all 0.1s ease;
    border-radius: 15px;
    display: inline-block;
    cursor: pointer;
    /* opacity: ${({ active }) => (active === "isActive" ? 1 : 0.5)}; */

    &:hover {
      transition: all 0.3s ease;
      opacity: 1;
      transform: translateY(-5px);
    }
  }
`;

export const ActivityContainer = styled.div`
  padding-top: 8rem;
  width: 100%;
  position: relative;
  max-width: 160rem;
  margin: 0 auto;
`;

export const CardsContainer = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const StyledCardActivity = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 90rem;
  background: #fff;
  border: solid 1px #1f5dfe;
  padding: 3rem 4rem;
  margin: 0 auto;
  /* margin-bottom: 5rem; */
  border-radius: 4px;
  flex: 1;
  gap: 2rem;
  z-index: 2;

  div {
    width: 100%;
    height: auto;
    position: relative;
    flex: 40%;

    img {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
    }
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
    flex: 60%;
    font-weight: 400;
  }
`;

//DONATION SECTION

export const DonationSection = styled.div`
  background: #f3f3f3;
  background-size: cover;
  padding: 10rem 0;
  /* margin: 10rem 0; */
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
`;

export const DonationContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  /* justify-content: space-between; */
  gap: 2.5rem;
  max-width: 90rem;
  /* max-height: 40rem; */
  /* height: 100%; */
  border-radius: 4px;
  position: relative;
  margin: 4rem auto;
  padding: 5rem 0;
  background: #eef0f7;
`;

export const DonationImageBox = styled.div`
  /* width: 100%; */
  /* height: 100%; */
  position: relative;
  img {
    height: 100%;
    width: 100%;
    display: block;
  }
  div {
    position: relative;
    transform: translate(0, -40rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.68);
    height: 20%;
    width: 100%;
    letter-spacing: 0.1rem;

    h2 {
      position: absolute;
      text-transform: uppercase;
      color: #fff;
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.5rem;
    }
  }
`;

export const DonationTextBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DonationTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #7d90b2;
  /* height: 100%; */
  width: 85%;
  height: 90%;
  padding: 2rem;

  button {
    padding: 1rem 1.5rem;
    background: #f1cb00;
    border: none;
    border-radius: 4px;
    color: #212529;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background: #0ea5e9;
      /* border: 1.5px solid #0ea5e9; */
    }
  }
`;

export const TextBoxHeader = styled.div`
  text-transform: uppercase;
  font-size: 1.7rem;
  border: 1px solid #212529;
  padding: 1rem 2rem;
  margin: 4rem 0;
`;
export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 2rem 2.8rem;
  background: #fff;
  box-shadow: 0px 0px 7px #0ea5e9;
  width: 80%;

  h3 {
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  button {
    padding: 1rem 1.5rem;
    cursor: pointer;
  }
  button:nth-child(1) {
    background: none;
    border: 1.5px solid #212529;
    border-radius: 4px;
    color: #212529;
    font-weight: 600;

    &:hover {
      background: #0ea5e9;
      border: 1.5px solid #0ea5e9;
    }
  }
  button:nth-child(2) {
    background: #f1cb00;
    border: none;
    border-radius: 4px;
    color: #212529;
    font-weight: 600;

    &:hover {
      background: #0ea5e9;
    }
  }
`;
export const ConsentText = styled.div`
  display: flex;
  font-size: 1.2rem;
  justify-content: space-around;
  margin-left: 2.5rem;
  gap: 1.2rem;
`;

//BLOG SECTION
export const BlogSection = styled.section`
  display: grid;
  padding: 15rem 10rem;
`;

export const BlogHeader = styled.div`
  display: flex;
  color: #212529;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  h3 {
    font-size: 3.2rem;
    margin-left: 11.5rem;
  }
  span {
    font-size: 1.6rem;
    margin-right: 20rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #212529;

    cursor: pointer;

    &:hover {
      color: #999;
    }
  }
`;

export const BlogBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 25rem));
  gap: 5rem;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
`;
export const BlogStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  justify-content: space-between;
  background: #fe1f6f;
  height: 30rem;
  color: #fff;
`;

export const ActionBlock = styled.div`
  display: flex;
  border-bottom: 1px solid #fff;
  padding-bottom: 1.2rem;
  cursor: pointer;

  &:hover {
    color: #f0eeee;
  }

  span {
    font-size: 1.6rem;
    margin-right: 1.2rem;
  }
  svg {
    width: 20px;
    height: 100%;
    margin: auto 0;
  }
`;

//VIEWBLOG
export const StyledViewCard = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledBlogCard = styled.div`
  max-width: 42rem;
  display: block;
  padding: 1.5rem;
  margin: 5rem;
  background: #ffffff;
  border: 1px solid #0ea5e9;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  h3 {
    font-size: 1.6rem;
  }
`;
export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  p {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  div {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    gap: 2rem;
    color: #0ea5e9;

    span {
      font-size: 1.2rem;
      position: relative;

      &::before {
        content: "";
        height: 12px;
        width: 2px;
        background-color: #fe5000;
        display: block;
        left: -10px;
        bottom: 0;
        top: 0;
        position: absolute;
      }
    }
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AuthorInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

//TESTIMONIAL SECTION
export const StyledTestimonial = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 9rem;
  /* margin: 15rem 0; */
  padding: 15rem 10rem;
  background: #7d90b2;
`;

export const StyledTestimonialDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #fff;

  ${SectionSubheader} {
    margin-left: 0;
  }

  h1 {
    font-size: 6.2rem;
  }
  p {
    font-size: 1.6rem;
  }
`;

export const QuoteTop = styled.div`
  svg {
    width: 3.5rem;
    height: auto;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    text-transform: uppercase;
    font-size: 1.5rem;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }

  svg {
    width: 30px;
    height: auto;
    cursor: pointer;
  }
`;

export const ArrowNavigation = styled.div`
  display: flex;
`;

export const CardTestimonialContainer = styled.div`
  display: flex;
  gap: 3rem;
  position: relative;
`;
export const LeftArrow = styled(FaIcons.FaCaretSquareLeft)`
  position: absolute;
  display: block;
  width: 35px;
  height: auto;
  left: -5rem;
  top: 50%;
  z-index: 2;
  cursor: pointer;
`;
export const RightArrow = styled(FaIcons.FaCaretSquareRight)`
  position: absolute;
  display: block;
  width: 35px;
  height: auto;
  right: -5rem;
  top: 50%;
  cursor: pointer;
`;

export const StyledCardTestimonial = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 30rem;
  height: 40rem;
  background: #fff;
  padding: 2rem 1.5rem;
  position: relative;
`;

export const ImageContainer = styled.div`
  img {
    height: 100%;
    width: 100%;
    display: block;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #e5e5e5;
  align-self: stretch;
  padding: 1.2rem;

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.7rem;
  }
  span {
    font-size: 1.2rem;
  }
`;

export const QuoteContainer = styled.div`
  background: #2e3b52;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  top: 19rem;
  margin-left: 1.2rem;

  svg {
    color: #fff;
    margin: 0 auto;
  }
`;

export const FooterSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  background: #212529;
  color: #fff;

  font-size: 1.2rem;
  justify-content: center;
`;

export const TopPart = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1.2rem;
`;
export const BottomPart = styled.div`
  align-self: center;
`;

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LinkBlock = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  list-style: none;
  li {
    text-decoration: none;
    margin-bottom: 1rem;
  }
`;
export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

export const DisplayBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  background: #f1cb00;
  display: flex;
  justify-content: center;
  svg {
    display: block;
    width: 16px;
    height: 100%;

    color: #212529;
  }
`;

export const TextField = styled.input`
  width: 30rem;
  height: 3rem;
`;
