import React, { useState, useEffect } from "react";
import { GraphCMSContent } from "../../services";
const CommentsForm = ({ slug }) => {
  const [error, setError] = useState(false);
  const [localStorage, setLocalStorage] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    comment: null,
    storeData: false,
  });

  //Essay
  const [newComment, SetNewComment] = useState({});
  //
  const Client = new GraphCMSContent();
  const createComment = async () => {
    const res = Client.CreateComment(newComment);
    console.log("response", newComment);
    if (res !== false) alert("New Comment Created Successfully!");
    else alert("An error occurred!");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createComment();
    e.target.reset();
  };

  return (
    <div>
      <h4> CommentsForm</h4>

      <form onSubmit={handleSubmit}>
        <div>
          <label>
            {" "}
            Name
            <input
              onChange={(e) =>
                SetNewComment({
                  ...newComment,
                  slug: slug,
                  name: e.currentTarget.value,
                })
              }
            />
          </label>
        </div>

        <div>
          <label>
            {" "}
            Content
            <textarea
              onChange={(e) =>
                SetNewComment({
                  ...newComment,
                  slug: slug,
                  comment: e.currentTarget.value,
                })
              }
            ></textarea>
          </label>
        </div>

        <div>
          <button type="submit">Create Comment</button>
        </div>
      </form>
    </div>
  );
};

export default CommentsForm;