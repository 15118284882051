import React from "react";
import "./Document.styled";
import CharteImage from "../../assets/charte.PNG";
import { Shelf, Book } from "./Document.styled";
const Document = () => {
  return (
    <>
    <h1>Overview Component</h1>
    <div>
    <Shelf>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
    </Shelf>

    <Shelf>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
      <Book>
      <img src={CharteImage} alt="charte" />
      </Book>
    </Shelf>
    </div>
    
    </>
  );
};

export default Document;
