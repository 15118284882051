import React from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { SidebarNav, MenuItems, MenuItemLinks, Nav } from "./Sidebar.styles";

const SidebarData = [
  {
    title: "Dashboard",
    path: "/member/dashboard",
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: "Document",
    path: "/member/document",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Users",
        path: "/overview/users",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Revenue",
        path: "/overview/revenue",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Reports",
    path: "/member/reports",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Reports",
        path: "/reports/reports1",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Reports 2",
        path: "/reports/reports2",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Reports 3",
        path: "/reports/reports3",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Products",
    path: "/member/products",
    icon: <FaIcons.FaCartPlus />,
  },
  {
    title: "Team",
    path: "/member/team",
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: "Settings",
    path: "/member/setting",
    icon: <IoIcons.IoIosPaper />,
  },
  // {
  //   title: "Messages",
  //   path: "/messages",
  //   icon: <FaIcons.FaEnvelopeOpenText />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: "Message 1",
  //       path: "/messages/message1",
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //     {
  //       title: "Message 2",
  //       path: "/messages/message2",
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //   ],
  // },
  // {
  //   title: "Support",
  //   path: "/member/support",
  //   icon: <IoIcons.IoMdHelpCircle />,
  // },
];

const Sidebar = () => {
  return (
    <SidebarNav>
      {SidebarData.map((item, index) => {
        return (
          <MenuItems key={index}>
            <MenuItemLinks to={`${item.path}`}>
              {item.icon}
              <span style={{ marginLeft: "16px" }}>{item.title}</span>
            </MenuItemLinks>
            {/* <Route path={`${match.path}/:i} /> */}
          </MenuItems>
        );
      })}
    </SidebarNav>
  );
};

export default Sidebar;
