import React from "react";
import {
  FaCalendar,
  FaDollarSign,
  FaThumbsUp,
  FaUser,
  FaVideo,
} from "react-icons/fa";
import {
  MainContainer,Container,
  ContainerTitle,
  ContainerGreeting,
  Paragraph,
  ContainerCards,
  ContainerCard,
  CardInner,
  TextPrimary
  ,TextTitle,
  TextLightblue,
  TextYellow,
  TextRed,
  TextGreen
} from './DashboardHome.styled'
import hello from "../../assets/hello.svg";
const Dashboadhome = ({ children }) => {
  return (
    <Container>
    <ContainerTitle>
      <img src={hello} alt="hello"/>
      <ContainerGreeting>
        <h1>Hello Mhatriciste</h1>
        <Paragraph>
         Welcome to your Member dashboard
      </Paragraph>
      </ContainerGreeting>
      
    </ContainerTitle>

    <ContainerCards>
      <ContainerCard>
      <TextLightblue>
         <FaUser />
      </TextLightblue>
      <CardInner>
        <TextPrimary>
        Number of Subscribers
        </TextPrimary>
        <TextTitle>578</TextTitle>
      </CardInner>
      </ContainerCard>

      <ContainerCard>
      <TextGreen>
      <FaThumbsUp />
      </TextGreen>
      <CardInner>
        <TextPrimary>
        Number of Subscribers
        </TextPrimary>
        <TextTitle>578</TextTitle>
      </CardInner>
      </ContainerCard>

      <ContainerCard>
      <TextRed>
      <FaCalendar />
      </TextRed>
      <CardInner>
        <TextPrimary>
        Number of Subscribers
        </TextPrimary>
        <TextTitle>578</TextTitle>
      </CardInner>
      </ContainerCard>
      <ContainerCard>
      <TextYellow>
      <FaVideo />
      </TextYellow>
      <CardInner>
        <TextPrimary>
        Number of Subscribers
        </TextPrimary>
        <TextTitle>578</TextTitle>
      </CardInner>
      </ContainerCard>
      
    </ContainerCards>
    </Container>
  );
};

export default Dashboadhome;
