import React from "react";
import { Flex } from "../components/Flex/Flex.styled";

const Decouvrir = () => {
  return (
    <Flex>
      <h1>Decouvrir</h1>
    </Flex>
  );
};

export default Decouvrir;
