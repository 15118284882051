import React from "react";

import {
  AboutSection,
  CardAboutContainer,
  ImgBg,
  SectionSubheader,
  StyledAboutBg,
} from "./Home.styled";
import CardAbout from "./CardAbout";
import { aboutContent } from "./content";
import AboutBg from "../../assets/images/AboutBg.jpg";

const About = () => {
  return (
    <>
      <AboutSection>
        <StyledAboutBg>{/* <ImgBg src={AboutBg} /> */}</StyledAboutBg>
        <SectionSubheader>À propos</SectionSubheader>
        <h2>Qui sommes-nous?</h2>
        <CardAboutContainer>
          {aboutContent.map((item, index) => (
            <CardAbout key={index} item={item} />
          ))}
        </CardAboutContainer>
      </AboutSection>
    </>
  );
};

export default About;
