import React from "react";

import * as FaIcons from "react-icons/fa";
import {
  ActionBlock,
  BlogBox,
  BlogHeader,
  BlogSection,
  BlogStyled,
  SectionSubheader,
} from "./Home.styled";

const BlogContainer = () => {
  return (
    <>
      <BlogSection>
        <SectionSubheader>NOTRE BLOG</SectionSubheader>
        <BlogHeader>
          <h3>Les articles les plus lus de notre blog.</h3>
          <span>Voir tous les articles</span>
        </BlogHeader>

        <BlogBox>
          <BlogStyled>
            <h2>"QUE LA LUMIÈRE SOIT", une lueur d'espoir qui s'allume!</h2>
            <ActionBlock>
              <span>Lire la suite</span>
              <FaIcons.FaLongArrowAltRight />
            </ActionBlock>
          </BlogStyled>
          <BlogStyled>
            <h2>"QUE LA LUMIÈRE SOIT", une lueur d'espoir qui s'allume!</h2>
            <ActionBlock>
              <span>Lire la suite</span>
              <FaIcons.FaLongArrowAltRight />
            </ActionBlock>
          </BlogStyled>
          <BlogStyled>
            <h2>"QUE LA LUMIÈRE SOIT", une lueur d'espoir qui s'allume!</h2>
            <ActionBlock>
              <span>Lire la suite</span>
              <FaIcons.FaLongArrowAltRight />
            </ActionBlock>
          </BlogStyled>
        </BlogBox>
      </BlogSection>
    </>
  );
};

export default BlogContainer;
