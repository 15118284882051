import React from "react";
import { Flex } from "../components/Flex/Flex.styled";

const Donation = () => {
  return (
    <Flex>
      <h1>Donation Page</h1>
    </Flex>
  );
};

export default Donation;
