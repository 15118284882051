import React from "react";
import { useState } from "react";
import activityData from "./content";
import {
  ActivityContainer,
  ActivityHeader,
  ActivitySection,
  ActivityTabs,
  CardsContainer,
  LeftArrow,
  RightArrow,
  SectionSubheader,
} from "./Home.styled";
import CardActivity from "./CardActivity";
import { Flex } from "../../components/Flex/Flex.styled";

const Activity = (items = { activityData }) => {
  return (
    <>
      <ActivitySection>
        <ActivityHeader>
          <SectionSubheader>nos activités</SectionSubheader>
          <h2>Découvrez nos activités riches et instructives.</h2>
        </ActivityHeader>

        <CardsContainer>
          <CardActivity items={activityData} />;{/* })} */}
        </CardsContainer>
      </ActivitySection>
    </>
  );
};

export default Activity;
