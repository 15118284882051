import React from "react";
import {
  ButtonBlock,
  ButtonsContainer,
  ConsentText,
  DonationContainer,
  DonationImageBox,
  DonationSection,
  DonationTextBox,
  DonationTextBoxContainer,
  SectionSubheader,
  TextBoxHeader,
} from "./Home.styled";

// import DonationImage from "../../assets/images/donate-img.jpg";
import { Button } from "../../components/Button/Button.styled";
// import { ButtonDefault } from "../../styles/Button.styled";

const DonationForm = (props) => {
  return (
    <>
      <DonationSection>
        <SectionSubheader>faire un don pour sauver</SectionSubheader>
        <DonationContainer>
          <DonationImageBox>
            <img
              src={require("../../assets/images/donate-img.jpg")}
              alt="Girl smiling"
            />
            <div>
              <h2>contribuer à notre cause</h2>
            </div>
          </DonationImageBox>
          <DonationTextBoxContainer>
            <DonationTextBox>
              <TextBoxHeader>
                <h2>FAITES PARTIE DE QUELQUE CHOSE DE PLUS GRAND</h2>
              </TextBoxHeader>
              <ButtonBlock>
                <h3>je veux faire un don</h3>
                <ButtonsContainer>
                  <button>SINGULIER</button>
                  <button>RÉGULIER</button>
                </ButtonsContainer>
                <ConsentText>
                  <div>
                    <input type="checkbox" />
                  </div>

                  <div>
                    <p>
                      J'accepte de faire ce don à MhatriX NG. Ils peuvent
                      l'utiliser dans n'importe lequel de leurs activités ou
                      dans l'activité que je choisirai.
                    </p>
                  </div>
                </ConsentText>
              </ButtonBlock>
              <button>Finir le processus</button>
            </DonationTextBox>
          </DonationTextBoxContainer>
        </DonationContainer>
      </DonationSection>
    </>
  );
};

export default DonationForm;
