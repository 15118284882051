import React from 'react'
import { Outlet } from 'react-router-dom'
import BlogNavbar from '../components/Navbar/Blog-Navbar'
import styled from "styled-components";

export const Container = styled.div`
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "Post News"
      "Article Article";
    /* flex-wrap: wrap; */
  }
`;


const BlogLayout = ({children}) => {

  
  return (
    <>
      <BlogNavbar />
        {/* local layout UI */}
        {/* {children} */}
    <Outlet /> 
    </>
  )
}

export default BlogLayout