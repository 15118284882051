import { configureStore, combineReducers } from "@reduxjs/toolkit";
import categoriesReducers from "./reducers/categoriesReducers";
import postsReducer from "./reducers/postsReducers";
const rootReducer = combineReducers({
  posts: postsReducer,
  categories: categoriesReducers,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
