import styled from "styled-components";

export const MainContainer =styled.main`
background: #f3f4f6;
overflow-y: auto;
`
export const Container = styled.div`
  padding: 20px 35px;
`
export const ContainerTitle = styled.div`
 display: flex;
 align-items: center;
 img{
    max-height: 100px;
    object-fit: contain;
    margin-right: 20px;
 }
`
export const ContainerGreeting = styled.div`
h1{
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}
`
export const Paragraph = styled.p`
 font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
`
export const ContainerCards = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin: 20px 0;
`
export const ContainerCard =styled.div`
 display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70px;
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
`
export const CardInner =styled.div`
 display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        font-size: 25px;
    }
`
export const TextPrimary = styled.p``
export const TextTitle = styled.span`
color: #2e4a66;
`
export const TextLightblue = styled.i`
    color: #469cac;`


export const TextRed= styled.i`
   color: #cc3d38;`

export const TextYellow= styled.i`
color: #a98921;
`

export const TextGreen= styled.i`
color: #3b9668;
`