import { createGlobalStyle, GlobalStyleComponent } from "styled-components";
import device from "./BreakPoint";

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
    *{
        margin: 0;
        padding: 0;
        
    }
    *,
    ::after,
    ::before {
        box-sizing: inherit;
    }

    html{
        box-sizing: border-box;
        font-size:62.5%;


          @media only screen and ${device.laptopL} {
            font-size: 56.25%;
        }
        @media only screen and ${device.tablet} {
            font-size: 50%;
        }
        /* @media only screen and ${device.desktopL} {
            font-size: 75%;
        } */
       
    
        --color-primary:#0EA5E9;
        --color-primary-light:#00C0EB;
        --color-primary-light-1:#00D6D6;
        --color-primary-light-2:#3CE8B1;
        --color-primary-hover: #028ccc;
        --color-gray: #949494;
        --color-gray-light: #bbb;
        --color-grey-light-1: #faf9f9;
        --color-grey-light-2: #f4f2f2;
        --color-grey-light-3: #f0eeee;
        --color-grey-light-4: #ccc;
        --color-grey-dark:#3B3B3B;
        --color-grey-dark-1: #333;
        --color-grey-dark-2: #777;
        --color-grey-dark-3: #999;
        --color-grey-dark-4: #212529;

        --color-black:#000;
        --color-white: #ffff;
        --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, .3);
        --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.6);
        --primary-font: 'Roboto', sans-serif;
        --default-font-size: 1.6rem;
        
    }
    body{
        font-family:'Roboto', sans-serif;
        font-weight: 400;
        color: #212529;
        cursor: default;
        
    }
`;

export default GlobalStyles;
