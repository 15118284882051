import React, { useState, useEffect } from "react";
import moment from "moment";

import { getRecentPosts, getSimilarPosts } from "../../services";
import { Link, useNavigate} from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  /*  */
  display: flex;
  flex-direction: column;
  

  margin: 1.25rem 5rem 0 5rem;
  @media only screen and (min-width: 768px) {
    grid-area: News;
  }
`;
const LastNewsHeader = styled.div`
  margin: 5rem 0.5rem;
  /* display: flex;
  justify-content: space-between; */
  border-bottom: 1px solid #c4c4c4;
  h2 {
    float: right;
  }
`;
const Span = styled.span`
  color: #7e7e7e;
  margin: 1rem 1.5px 0 1.5px;
`;
const CardNouvelleWrapper = styled.div`
  display: flex;
  font-size: 1.5rem;
  flex-direction: column;

  margin-bottom: 5rem;
`;
const CardNouvelle = styled.div`
  /* width: 100%; */
  margin-bottom: 2rem;
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const VoirPlusButton = styled.button`
  color: #1f5dfe;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #1f5dfe;
`;
const CtaButton = styled.button`
  background: #0ea5e9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
`;

const Postwidget = ({ categories, slug }) => {
  const navigate = useNavigate();
  const [relatedPosts, setRelatedPosts] = useState([]);

  console.log("related post", relatedPosts);
  useEffect(() => {
    if (slug) {
      getSimilarPosts(categories, slug).then((result) => {
        setRelatedPosts(result);
      });
    } else {
      getRecentPosts().then((result) => {
        setRelatedPosts(result);
      });
    }
  }, [slug]);
  return (
    <Wrapper>
      <LastNewsHeader>
        <h2>{slug ? "Related posts" : "Recent Posts"}</h2>
      </LastNewsHeader>
      <CardNouvelleWrapper>
        {
          relatedPosts?(
            relatedPosts.map((post, index)=>(
               <CardNouvelle>
          <h3>
            {post.title}
            {/* Gagner un rabais de 50% pour une carte VIP de la 4e édition de
            QPS... */}
          </h3>
          <BottomSection>
            <Span>{moment(post.createdAt).format("MMM DD, YYYY")}</Span>
            <VoirPlusButton onClick={() => navigate("/blog/" + post.slug)}>Voir Plus</VoirPlusButton>
          </BottomSection>
        </CardNouvelle>
            ))
          ):(  <h1>No related post</h1>)
        }
       
       
        <CtaButton>Voir Toutes Les Dernieres Nouvelles</CtaButton>
      </CardNouvelleWrapper>
      {/* {relatedPosts ? (
        relatedPosts.map((post, index) => (
          <div key={index}>
            <div>
              <div>
                {post.coverImage ? (
                  <img
                    src={post.coverImage.url}
                    alt={post.title}
                    height="60px"
                    width="60px"
                  />
                ) : (
                  <h1>No Image</h1>
                )}
              </div>
              <p>{moment(post.createdAt).format("MMM DD, YYYY")}</p>
              <Link to={`${post.slug}`}>{post.title}</Link>
            </div>
          </div>
        ))
      ) : (
        <h1>No related post</h1>
      )} */}
    </Wrapper>
  );
};

export default Postwidget;