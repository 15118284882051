import styled from "styled-components";

export const Shelf = styled.ul`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    border-bottom: 30px solid #a5a5a5;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: -15px;
    z-index: 10;
`
export const Book = styled.li`
  border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
    margin-top: 2rem;
    cursor: pointer;
    display: block;
    width: 150px;
    height: 220px;
    position: relative;
    background: lighten(#fde3a7, 20%);
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(#000, .1), 0 9px 20px 0 rgba(#000, .25);
    overflow: hidden;
    transition: box-shadow .3s linear;

    img{
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: inherit;
    height: inherit;
    transform-origin: 0 50%;
    transform: rotateY(0);
    transition: all .45s ease;
    }
    :hover{
        box-shadow: 0 2px 4px 0 rgba(#000, .25), 0 9px 20px 0 rgba(#000, .45);
        img{
            transform: rotateY(-25deg);
            box-shadow: 1px 1px 5px 5px rgba(#000, .2);
        }
    }
    ::after,
    ::before{
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: -1;
    top: 0;
    background: lighten( #fde3a7, 20%);
    border: 1px solid lighten(#000, 85%);
    }
    ::before{
        left: -3px;
    }
    ::after{
        left: -6px;
    }
`