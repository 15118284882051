// const size = {
//   bpLargest: "75em", //1200px
//   bpLarge: "68.75em", //1100px
//   bpMedium: "56.25em", //900px
//   bpSmall: "37.5em", //600px
//   bpSmallest: "31.25em", //500px
// };
// const device = {
//   bpLargest: `(max-width:${size.bpLargest})`,
//   bpLarge: `(max-width:${size.bpLarge})`,
//   bpMedium: `(max-width:${size.bpMedium})`,
//   bpSmall: `(max-width:${size.bpSmall})`,
//   bpSmallest: `(max-width:${size.bpSmallest})`,
// };
// export default { size, device };
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

// const size = {
//   bpLargest: "75em", //1200px
//   bpLarge: "68.75em", //1100px
//   bpMedium: "56.25em", //900px
//   bpSmall: "37.5em", //600px
//   bpSmallest: "31.25em", //500px
// };
// const device = {
//   bpLargest: `(max-width:${size.bpLargest})`,
//   bpLarge: `(max-width:${size.bpLarge})`,
//   bpMedium: `(max-width:${size.bpMedium})`,
//   bpSmall: `(max-width:${size.bpSmall})`,
//   bpSmallest: `(max-width:${size.bpSmallest})`,
// };
export default { size, device };
