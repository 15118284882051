import React from "react";
import { Flex } from "../components/Flex/Flex.styled";

const Activities = () => {
  return (
    <Flex>
      <h1>Activities Page</h1>
    </Flex>
  );
};

export default Activities;
