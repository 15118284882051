import React from "react";
import styled from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const Container = styled.div`
  font-size: 2rem;
  margin: 1.25rem 1rem 0 1rem;
  border-radius: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* max-width: 1200px; */
  background: #fff;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 1px 20px 0 #0ea5e9;
  :hover {
    box-shadow: 0 1px 3px 0 #0ea5e9;
  }
`;
const CardBody = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 1rem;
  }
`;
const Span = styled.span`
  color: #7e7e7e;
  margin: 1rem 1.5px 0 1.5px;
  :not(:last-child) {
    border-right: 1px solid #7e7e7e;
  }
`;
const CardFooter = styled.div`
  width: 100%;
`;
const CardUser = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const CardUserImage = styled.img`
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  margin-bottom: 1rem;
`;
const CardUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c4c4c4;
`;
const Wrapper = styled.div`
  /* grid-area: ArticleCard; */
  @media only screen and (min-width: 768px) {
    grid-area: Article;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonArrow = styled.button`
  background: #1f5dfe;
  outline: none;
  color: white;
  border-radius: 50%;
  padding: 0.8rem;
  margin: 0.5rem;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
`;
const Articlecard = () => {
  return (
    <Wrapper>
      <ButtonArrow>
        <FaArrowLeft />
      </ButtonArrow>
      <Container>
        <CardBody>
          <h2>What's new in 2022 Tech</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
            perferendis molestiae non nemo doloribus. Doloremque, nihil! At ea
            atque quidem!
          </p>
          <Span>Société | 21-02-2022</Span>
        </CardBody>
        <CardFooter>
          <CardUser class="user">
            <CardUserInfo>
              <span>Ded Fegens Baptiste</span>
              <small>Energy Engineering Student</small>
            </CardUserInfo>{" "}
            <CardUserImage
              src="https://i.pravatar.cc/40?img=1"
              alt="user__image"
            />
          </CardUser>
        </CardFooter>
      </Container>
      <ButtonArrow>
        <FaArrowRight />
      </ButtonArrow>
    </Wrapper>
  );
};

export default Articlecard;