import React from "react";
import {
  AuthorInfoBox,
  Bottom,
  Middle,
  StyledBlogCard,
  Top,
} from "./Home.styled";

function BlogCard({
  item: { id, title, tag, category, date, author, authorFormation, avatar },
}) {
  return (
    <StyledBlogCard>
      <div>
        <Top>
          <h3>{title}</h3>
        </Top>
        <Middle>
          <p>{tag}</p>
          <div>
            <h3>{category}</h3>
            <span>{date}</span>
          </div>
        </Middle>
        <Bottom>
          <AuthorInfoBox>
            <h2>{author}</h2>
            <span>{authorFormation}</span>
          </AuthorInfoBox>
          <div>
            <img src={require(`../../assets/images/${avatar}`)} alt="" />
          </div>
        </Bottom>
      </div>
    </StyledBlogCard>
  );
}

export default BlogCard;
