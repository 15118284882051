import React from "react";

import * as FaIcons from "react-icons/fa";
import {
  BottomPart,
  DisplayBlock,
  FooterSection,
  FormBlock,
  IconContainer,
  LinkBlock,
  NameBlock,
  TextField,
  TopPart,
} from "./Home.styled";

const Footer = () => {
  return (
    <>
      <FooterSection>
        <TopPart>
          <NameBlock>
            <h3>MhatriX NG</h3>
            <p>Nous créons une nouvelle perspective pour un avenir assuré.</p>
          </NameBlock>
          <LinkBlock>
            <li>Accueil</li>
            <li>Découvrir</li>
            <li>Activités</li>
            <li>Blog</li>
            <li>Donate</li>
            <li>Partenariat</li>
            <li>Bénévolat</li>
          </LinkBlock>
          <FormBlock>
            <h4>Inscrivez-vous à notre Newsletter</h4>
            <DisplayBlock>
              <TextField
                type="email"
                label="Email"
                placeholder="email@example.com"
              />
              <IconContainer>
                <FaIcons.FaLongArrowAltRight />
              </IconContainer>
            </DisplayBlock>
          </FormBlock>
        </TopPart>
        <BottomPart>© Copyright 2022 - MhatriX NG</BottomPart>
      </FooterSection>
    </>
  );
};

export default Footer;
