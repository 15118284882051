import { data } from "autoprefixer";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPostDetails, getComments } from "../../services";
import { CategorySlice } from "../../store/reducers/categoriesReducers";
import Comments from "../Comments/Comments";
import Postwidget from "./PostWidget";
import { PostDetailFlex, PostDetailWrapper } from './../../styles/PostDetails.styled';


const Postdetail = ({ post }) => {
  let params = useParams();
  const [state, setstate] = useState("");
  console.log("state krss", state);
  useEffect(() => {
    const postDetails = async () => {
      const data = await getPostDetails(params.postId);

      setstate(data);
    };
    postDetails().catch(console.error);
  }, []);
  const getContentFragment = (index, text, obj, type) => {
    let modifiedText = text;

    if (obj) {
      if (obj.bold) {
        modifiedText = <b key={index}>{text}</b>;
      }

      if (obj.italic) {
        modifiedText = <em key={index}>{text}</em>;
      }

      if (obj.underline) {
        modifiedText = <u key={index}>{text}</u>;
      }
    }

    switch (type) {
      case "heading-three":
        return (
          <h3 key={index} className="text-xl font-semibold mb-4">
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </h3>
        );
      case "paragraph":
        return (
          <p key={index} className="mb-8">
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </p>
        );
      case "heading-four":
        return (
          <h4 key={index} className="text-md font-semibold mb-4">
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </h4>
        );
      case "image":
        return (
          <img
            key={index}
            alt={obj.title}
            height={obj.height}
            width={obj.width}
            src={obj.src}
          />
        );
      default:
        return modifiedText;
    }
  };

  return (
    <>
      {state ? (
        <PostDetailWrapper >
            <div>
            <h1>{state.title}</h1>
        
            <hr />
            </div>
          <PostDetailFlex>
          <div>
            {state.coverImage ? (
              <img
                src={state.coverImage.url}
                alt={state.title}
                height="250px"
                width="300px"
                style={{ borderRadius: 27 }}
              />
            ) : (
              <h1>No Image</h1>
            )}
          </div>
          {/* ----  */}
          <div>
          <p>
            {state.content.raw.children.map((typeObj, index) => {
              const children = typeObj.children.map((item, itemindex) =>
                getContentFragment(itemindex, item.text, item)
              );

              return getContentFragment(index, children, typeObj, typeObj.type);
            })}{" "}
          </p>

            </div>
            </PostDetailFlex>

          <Postwidget
            slug={state.slug}
            // categories={state.categories.map((category) => category.slug)}
            categories={state.categories.map((category) => category.slug)}
          />

          <Comments slug={state.slug} />
        </PostDetailWrapper>
      ) : (
        <p>no ides</p>
      )}
     
      
    </>
  );
};

export default Postdetail;