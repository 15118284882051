import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
const AppLayout = () => {
  return (
    <div>
      <Navbar />
        {/* local layout UI */}
    <    Outlet /> 
    </div>
  )
}

export default AppLayout