import React from "react";
import { StyledCardAbout } from "./Home.styled";

export default function CardAbout({ item: { id, icon, title, body } }) {
  return (
    <StyledCardAbout>
      <div>{icon}</div>
      <div>
        <h2>{title}</h2>
      </div>
      <div>
        <p>{body}</p>
      </div>
    </StyledCardAbout>
  );
}
