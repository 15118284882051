import { request, gql, GraphQLClient } from "graphql-request";

export class GraphCMSContent {
  constructor() {
    const token =
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NDYwNDQ4MDcsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NsMDZra3JjcDA1aDYwMXozOXQyeGdodjgvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiYTdhZjg2MDEtODg1Yi00N2RiLTk5MWYtYjY5YTJmMDQyYjk3IiwianRpIjoiY2thNWo0djJmMDN3bTAxejNhMmN2ZXo0ZCJ9.edJkYsWFnxetcLQUMku-6-IPgTCMYw5BAA56HAqFFFMAIh1cFhT9F0mehsVza_5GKzZA7XBH6ehvfwsotjgSyf11CBhYv3nna9qyIbG3ly7EMOKJAhB81rlr_qNP6ZaI1wGhZr0cemXU78HIg5GdfAU5pS_gV6vQxyENkKL6NzYWdnJofvCICKOenM54jUDo71YZDMKrsOnKWPM_1kYBp4p_BdSDrU_w0DtF3gs--p1nurV2Q6mQueAKwebpZcMoR_yAmGvkNsy6viI2K297cAYDE3-dkUodmd1LsSGHg0WrbUbN8_gskqfk4alMDpT1SPFtTQKmymhAzl5bWjN0SWpCq2hHfRlFXfwYhROkSbXCy4espDk1vRI26SNh18jojBRanQXOTInslvXMPkdLK-G8atvGlMwqtYyfC0QwkNrbN7abrdV4HDqe43W-bqGCerMoB1lUNKIdetrr6hsTCV6YxltOslomY46E8_c2OgCW-QtJ0BgqLynaGnz26YQ7G_Gg7CT3PqoHGFWcDiWJn1gzRWl6FbAygTE7QbFlBTbYgqiNUBjhg-lCEtjv2ySYueK5WAhR6R77gbYJdaTtiIWq7gxfNbinBZsU4wJzccXIY4os3719kVBHfcuZFFXaRG7nNGKbXnuSD6-sy257FxWd0WQRABiBCL-PrzLbvMo";
    this.Client = new GraphQLClient(GraphQlApi, {
      headers: {
        authorization: `Bearer  ${token}`,
      },
    });
  }

  CreateComment = async ({ name, comment, slug }) => {
    const QUERY = gql`
              mutation {
                  createComment(data: {
                      name: "${name}",
                      comment: "${comment}",
                   
                      post:{connect: {slug: "${slug}"}}
                     
                  }) {
                      id
                
                      name
                      comment, 
                      post{
                        slug
                      }
                  }
              }
          `;

    try {
      const data = await this.Client.request(QUERY);
      return data;
    } catch (error) {
      console.log("Error at createComment:>>", error);
      return false;
    }
  };
}
const GraphQlApi =
  "https://api-us-east-1.graphcms.com/v2/cl06kkrcp05h601z39t2xghv8/master";

export const getPostsGraphql = async () => {
  const query = gql`
    query MyQuery {
      postsConnection {
        edges {
          node {
            id
            createdAt
            slug
            title
            excerpt
            coverImage {
              url
            }
            author {
              biography
              name
              id
              picture {
                url
              }
            }
          }
        }
      }
    }
  `;
  const result = await request(GraphQlApi, query);
  return result.postsConnection.edges;
};

export const getCategoriesGraphQL = async () => {
  const query = gql`
    query GetGategories {
      categories {
        name
        slug
      }
    }
  `;

  const result = await request(GraphQlApi, query);

  return result.categories;
};

export const getComments = async (slug) => {
  const query = gql`
    query GetComments($slug: String!) {
      comments(where: { post: { slug: $slug } }) {
        name
        createdAt
        comment
      }
    }
  `;

  const result = await request(GraphQlApi, query, { slug });

  return result.comments;
};

export const getPostDetails = async (slug) => {
  const query = gql`
    query GetPostDetails($slug: String!) {
      post(where: { slug: $slug }) {
        title
        excerpt
        author {
          name
        }
        coverImage {
          url
        }
        createdAt
        slug
        content {
          raw
          text
        }
        categories {
          name
          slug
        }
      }
    }
  `;

  const result = await request(GraphQlApi, query, { slug });

  return result.post;
};

export const getRecentPosts = async () => {
  const query = gql`
    query GetPostDetails() {
      posts(
        orderBy: createdAt_ASC
        last: 3
      ) {
        title
       coverImage {
              url
            }
        createdAt
        slug
      }
    }
  `;
  const result = await request(GraphQlApi, query);

  return result.posts;
};
export const getSimilarPosts = async (categories, slug) => {
  const query = gql`
    query GetPostDetails($slug: String!, $categories: [String!]) {
      posts(
        where: {
          slug_not: $slug
          AND: { categories_some: { slug_in: $categories } }
        }
        last: 3
      ) {
        title
        coverImage {
          url
        }
        createdAt
        slug
      }
    }
  `;
  const result = await request(GraphQlApi, query, { slug, categories });

  return result.posts;
};