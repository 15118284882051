import React from "react";
import {
  StyledHero,
  PrimaryHeading,
  SecondaryHeading,
  HeroBg,
  ImgBg,
  HeroTextBox,
  Cta,
  HeroRight,
  HeroContainer,
  ButtonBox,
} from "./Home.styled";
import ImageBg from "../../assets/images/bg.jpg";
import africa from "../../assets/images/africa.jpg";
import africa1 from "../../assets/images/africa-1.jpg";
import { Button } from "../../components/Button/Button.styled";
import * as FaIcons from "react-icons/fa";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <>
      <StyledHero>
        <HeroBg>
          <ImgBg src={africa} />
        </HeroBg>
        <HeroContainer>
          <HeroTextBox>
            <PrimaryHeading>
              TRANSCENDER POUR MIEUX SE DISTINGUER
              {/* combattre <span>l'inégalité</span>
            <br />
            par des <span>opportunités</span> */}
            </PrimaryHeading>
            <SecondaryHeading>
              Nous créons des opportunités pour combattre les inégalités et
              l'exclusion
            </SecondaryHeading>
            <Cta>
              <Button variant="outline" to="/activites">
                Découvrir nos activités
              </Button>
              <motion.div
                animate={{
                  y: 8,
                }}
                transition={{
                  repeat: Infinity,
                  repeatDelay: 2,
                  type: "spring",
                  velocity: 1,
                }}
                drag
                dragTransition={{
                  min: 0,
                  max: 100,
                  bounceStiffness: 100,
                  ease: "easeInOut",
                }}
              >
                <FaIcons.FaChevronDown />
              </motion.div>
            </Cta>
          </HeroTextBox>

          <HeroRight>
            <p>
              Ensemble, nous pouvons créer un monde dépourvu d'inégalité.
              <br />
              Devenez un <span>partenaire</span> de MhatriX NG dans sa lutte.
            </p>
            <span>Ou faîtes un don.</span>
            <ButtonBox>
              <button>régulier</button>
              <button>une fois</button>
            </ButtonBox>
          </HeroRight>
        </HeroContainer>
      </StyledHero>
    </>
  );
};

export default Hero;
