import { motion } from "framer-motion";
import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { Button, ButtonDefault } from "../Button/Button.styled";

import {
  LeftSection,
  NavLink,
  LogoContainer,
  NavBar,
  Nav,
  NavMenu,
  HamMenu,
  CloseMenu,
  RightSection,
  NavContainer,
  UserIcon,
} from "./Navbar.styled";
import { Flex } from "../Flex/Flex.styled";
const Navbar = (props) => {
  const [active, setActive] = useState(false);
  const [colorChange, setColorChange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 500) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <NavBar
        background={
          colorChange ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.25)"
        }
      >
        <NavContainer>
          <LeftSection>
            <LogoContainer to="/">MhatriX NG</LogoContainer>
          </LeftSection>
          <Nav>
            {["accueil", "découvrir", "activites", "blog"].map((item) => (
              <li key={`Link-${item}`}>
                <div></div>
                <NavLink
                  // onClick={() => setActive === active}
                  // active={!active}
                  activeStyle={{ fontWeight: "bold", color: "#ff0000" }}
                  to={`/${item}`}
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </Nav>
          <RightSection>
            <Flex>
              <Button to="/donation">FAIRE UN DON</Button>
            </Flex>
            <Flex>
              {/* <Button variant="outline" to="/connecter"> */}
              <UserIcon />
              {/* Connecter */}
              {/* </Button> */}
            </Flex>
          </RightSection>
        </NavContainer>
      </NavBar>
    </>
  );
};

export default Navbar;
