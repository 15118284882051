import React from "react";
import styled from "styled-components";
const DasboardMain = styled.main`
  background: #f3f4f6;
  /* background: #cfcbcbcb; */
  flex: 1;
  height: 80vh;
  overflow-y: auto;
`;


const Dashboard = ({ children }) => {
  return <DasboardMain>{children}</DasboardMain>;
};

export default Dashboard;