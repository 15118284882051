import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  /*  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.25rem 5rem 0 5rem;
  @media only screen and (min-width: 768px) {
    grid-area: News;
  }
`;
const LastNewsHeader = styled.div`
  margin: 5rem 0.5rem;
  /* display: flex;
  justify-content: space-between; */
  border-bottom: 1px solid #c4c4c4;
  h2 {
    float: right;
  }
`;
const Span = styled.span`
  color: #7e7e7e;
  margin: 1rem 1.5px 0 1.5px;
`;
const CardNouvelleWrapper = styled.div`
  display: flex;
  font-size: 1.5rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
`;
const CardNouvelle = styled.div`
  /* width: 100%; */
  margin-bottom: 2rem;
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const VoirPlusButton = styled.button`
  color: #1f5dfe;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #1f5dfe;
`;
const CtaButton = styled.button`
  background: #0ea5e9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
`;
const Lastnews = () => {
  return (
    <Wrapper>
      <LastNewsHeader>
        <h2>Dernieres Nouvelles</h2>
      </LastNewsHeader>
      <CardNouvelleWrapper>
        <CardNouvelle>
          <h3>
            Gagner un rabais de 50% pour une carte VIP de la 4e édition de
            QPS...
          </h3>
          <BottomSection>
            <Span>21-02-2022</Span>
            <VoirPlusButton>Voir Plus</VoirPlusButton>
          </BottomSection>
        </CardNouvelle>
        <CardNouvelle>
          <h3>
            Gagner un rabais de 50% pour une carte VIP de la 4e édition de
            QPS...
          </h3>
          <BottomSection>
            <Span>21-02-2022</Span>
            <VoirPlusButton>Voir Plus</VoirPlusButton>
          </BottomSection>
        </CardNouvelle>
        <CtaButton>Voir Toutes Les Dernieres Nouvelles</CtaButton>
      </CardNouvelleWrapper>
    </Wrapper>
  );
};

export default Lastnews;