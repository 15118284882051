import React from "react";
// import {
//   MainContainer,
//   WelcomeText,
//   ButtonContainer,
//   LoginWith,
//   HorizontalRule,
//   IconsContainer,
//   InputContainer,
//   ForgotPassword,
// } from "../components/CardLogin/CardLogin.styles";
// import "./settings.css";
import styled from "styled-components";
import AvatarImage from "../../assets/user-1.jpeg";
// import Button from "../components/Button/Button-1";
import Memberform from "../Forms/MemberForm";

const Column1 = styled.div`
  background-color: #fff;
  flex: 0 0 60%;
  margin-right: 4.5rem;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.6);
  padding: 3rem;
`;
const Column2 = styled.div`
  /* flex: 1; */
  height: 45vh;
  background-color: #fff;
  margin-right: 4.5rem;
  /* padding: 0rem; */
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.6);
  border-radius: 10px;
`;
const MainContainer = styled.div`
  display: flex;
  padding: 2.5rem;
  font-size: 1.4rem;
  background-color: #faf9f9;
  border-bottom: 1px solid #f0eeee;
`;
const CardContainer = styled.div`
  /* width: 300px; */
  height: 230px; /**/
  /* background: #fff; */
  /* border-radius: 6px; */
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* box-shadow: 0px 1px 10px 1px #000; */
  /* overflow: hidden; */
  /* display: inline-block; */
`;
const CardUpperContainer = styled.div`
  height: 200px;
  /* width: 100%; */
  background: var(--color-primary);
`;
const CardImageContainer = styled.div`
  background: white;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  padding: 5px;
  transform: translate(8rem, 14rem);
  img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
  }
`;
const CardImage = styled.img``;
const CardLowerContainer = styled.div`
  text-align: center;

  padding-top: 2rem;
`;
const CardTextWrapper = styled.div`
  /* box-sizing: border-box; */
`;
const CardButtonWrapper = styled.div`
  display: flex;
  margin: 2rem;
  align-items: center;
  align-self: flex-end;
`;
const CardParagraph = styled.div`
  font-size: 16px;
  color: gray;
  margin-bottom: 20px;
`;
const CardText1 = styled.h3`
  line-height: 5;
  font-weight: lighter;
`;
const CardText2 = styled.h4`
  /* ${CardText1}; */
  line-height: 5;
  color: #7f00ff;
  opacity: 0.6;
  font-weight: bold;
`;
const Settings = () => {
  return (
    // <h1>Settings Pages</h1>
    <MainContainer>
      <Column1>
        <Memberform />
      </Column1>
      <Column2>
        <CardContainer>
          <CardUpperContainer>
            <CardImageContainer>
              <img src={AvatarImage} alt="" />
            </CardImageContainer>
          </CardUpperContainer>
          <CardLowerContainer>
            <CardTextWrapper>
              <CardText1>Karl Angrand</CardText1>

              <CardText2>Front End Developer</CardText2>
            </CardTextWrapper>

            {/* <CardTextWrapper>
              <CardParagraph>
                sodales accumsan ligula. Aenean sed diam tristique, fermentum mi
                nec, ornare arch.
              </CardParagraph>
            </CardTextWrapper> */}
            <CardButtonWrapper>
              {/* <a href="#">View Profile</a> */}
              {/* <Button content="Connect" />
              <Button content="Send Message" /> */}
            </CardButtonWrapper>
          </CardLowerContainer>
        </CardContainer>
      </Column2>
    </MainContainer>
    // <MainContainer background="#ffff">
    //   <WelcomeText>General Information</WelcomeText>
    // </MainContainer>
    // <section class="section-book">
    //   <div class="row">
    //     <div class="book">
    //       <div class="book__form">
    //         <form action="#" class="form">
    //           <div class="u-margin-bottom-medium">
    //             <h2 class="heading-secondary">Start Book Now</h2>
    //           </div>
    //           <div class="form__group">
    //             <input
    //               type="text"
    //               class="form__input"
    //               placeholder="Full Name"
    //               id="name"
    //               required
    //             />
    //             <label for="name" class="form__label">
    //               Full Name
    //             </label>
    //           </div>
    //           <div class="form__group">
    //             <input
    //               type="email"
    //               class="form__input"
    //               placeholder="Email Adress"
    //               id="email"
    //               required
    //             />
    //             <label for="email" class="form__label">
    //               Email
    //             </label>
    //           </div>
    //           <div class="form__group">
    //             <input
    //               type="email"
    //               class="form__input"
    //               placeholder="Email Adress"
    //               id="email"
    //               required
    //             />
    //             <label for="email" class="form__label">
    //               Email
    //             </label>
    //           </div>
    //           <div class="form__group">
    //             <button class="btn btn--green">Next Step &rarr;</button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Settings;
