import React from "react";
import Styles from "./MemberForm.styles";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, toDate } from "date-fns";
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

const required = (value) => (value ? undefined : "Required");
const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
);

const RenderDatePicker = ({ name, input, input: { value, onChange } }) => {
  return (
    <DatePicker
      locale="en"
      placeholderText={value}
      dateFormat="P"
      selected={value && isValid(value) ? toDate(value) : null} // needs to be checked if it is valid date
      disabledKeyboardNavigation
      name={name}
      onChange={(date) => {
        // On Change, you should use final-form Field Input prop to change the value
        if (isValid(date)) {
          input.onChange(format(new Date(date), "dd-MM-yyyy"));
        } else {
          input.onChange(null);
        }
      }}
    />
  );
};
const Memberform = () => {
  return (
    <Styles>
      <h2>General Information</h2>

      <Form
        onSubmit={onSubmit}
        initialValues={{ stooge: "larry", employed: false }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label>First Name</label>
              <Field
                name="firstName"
                component="input"
                type="text"
                placeholder="First Name"
              />
            </div>
            <div>
              <label>Last Name</label>
              <Field
                name="lastName"
                component="input"
                type="text"
                placeholder="Last Name"
              />
            </div>
            <div>
              <label>Date of birth</label>
              <Field
                name="dateOfBirth"
                component={RenderDatePicker}
                validate={required}
              />
              <Error name="dateOfBirth" />
            </div>

            <div>
              <label>Gender</label>
              <Field name="gender" component="select">
                <option />
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="N/A">N/A</option>
              </Field>
            </div>
            <div>
              <label>Email</label>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="name@company.com"
              />
            </div>
            <div>
              <label>Phone</label>
              <Field
                name="phone"
                component="input"
                type="number"
                placeholder="+17174711782"
              />
            </div>

            <h2>Address</h2>
            <div>
              <label>Address</label>
              <Field
                name="address"
                component="input"
                type="text"
                placeholder="Enter Your Home Address"
              />
            </div>
            <div>
              <label>City</label>
              <Field
                name="city"
                component="input"
                type="text"
                placeholder="City"
              />
            </div>

            <div>
              <label>Notes</label>
              <Field name="notes" component="textarea" placeholder="Notes" />
            </div>
            <div className="buttons">
              <button type="submit" disabled={submitting || pristine}>
                Submit
              </button>
              <button
                type="button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                Reset
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </Styles>
  );
};

export default Memberform;
