import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

export const NavBar = styled.nav`
  height: 60px;
  width: 100%;
  padding: 1rem 10rem;
  background: ${({ background }) => background};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: fixed;
  z-index: 999;
`;

export const Nav = styled.ul`
  flex: 1;
  max-width: 160rem;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-align: center;

  li {
    line-height: 1.2rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    font-weight: ${({ active }) => (active ? 600 : 400)};

    &:hover {
      transition: all 0.5s ease;

      /* div {
        display: block;
      } */
    }
    /* div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fe5000;
      display: none;
      position: absolute;
      top: 10px;
    } */

    /* &:after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;

      &::active:after {
        background: #fe5000;
      }
    }
    &:hover:after {
      width: 100%;
      background: #fe5000;
      transition: width 0.5s ease, background-color 0.5s ease;
    } */
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-width: 120rem;
  align-items: center;
  margin: 0 auto;
`;

export const UserIcon = styled(FaIcons.FaUserCircle)`
  color: var(--color-white);
  width: 20px;
  height: 20px;
  margin-left: -1.2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const HamMenu = styled(FaIcons.FaBars)`
  width: 70%;
  height: 70%;
  color: #fff;
`;

export const CloseMenu = styled(FaIcons.FaTimes)``;

export const NavLink = styled(LinkRouter)`
  text-decoration: none;
  height: 100%;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  color: #fff;
  text-transform: capitalize;

  &:hover {
    color: #f1cb00;
  }
`;

export const LeftSection = styled.div`
  display: flex;
`;

export const RightSection = styled.div`
  display: flex;
  gap: 5rem;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const LogoText = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  cursor: pointer;
`;

export const LogoContainer = styled(LinkRouter)`
  font-weight: 700;
  font-size: 2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: flex-start;
  color: var(--color-primary);
  cursor: pointer;
`;

export const LogoImg = styled.img`
  max-width: 100px;
  height: auto;
  color: #fff;
`;

export const NavMobile = styled.ul``;
