import React from "react";
import { Flex } from "../components/Flex/Flex.styled";

const Signin = () => {
  return (
    <Flex>
      <h1>Signin Page</h1>
    </Flex>
  );
};

export default Signin;
