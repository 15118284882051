import styled from 'styled-components'

export const PostDetailWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;

    h1 { 
        font-size: 4rem;
    }
   
`
export const PostDetailFlex = styled.div`
    display: flex;
    padding-top: 2.5rem;
   
   p { 
       padding-left: 2rem;
       font-size: 1.5rem;
   }
`