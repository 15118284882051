import React,{useState, useEffect} from 'react'
import PostPreview from '../components/Posts/PostPreview'
import ArticleCard from '../components/Posts/ArticleCard'
import PostCard from '../components/Posts/PostCard'
import Lastnews from '../components/Posts/LastNews'
import {useSelector, useDispatch} from 'react-redux'
import {fetchPosts, PostsSelector} from '../store/reducers/postsReducers'
import styled from 'styled-components'
import BlogLayout from '../layouts/BlogLayout'
import Postwidget from '../components/Posts/PostWidget'


const Container = styled.div`

  max-width: 1200px;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "Post News"
      "Article Article";
    /* flex-wrap: wrap; */
  }
`;


const Blog = () => {
  const dispatch = useDispatch();
  const { posts, loading, hasErrors } = useSelector(PostsSelector);

  console.log('posts', posts)
  const LoadingPostsJsx = () => <div>Loading...</div>;
  const ErrorLoadingPostsJsx = () => (
    <div className="mx-auto alert-danger">Error!</div>
  );
  useEffect(async () => {
    dispatch(fetchPosts());
  }, [dispatch]);

  //(very good) https://codesource.io/building-a-blogging-platform-using-react-graphql-and-graphcms/
  if (loading) return LoadingPostsJsx();
  if (hasErrors) return ErrorLoadingPostsJsx();

  return (
    
      <Container>
         {posts.slice(0,1).map((post)=>(
           <PostCard post={post} key ={post.node.id}/>
          ) )}
    <br />
    {/* <ArticleCard /> */}

    <br />

    {/* <Lastnews /> */}
    <Postwidget />

      <br />
      </Container>
      
  
  )
}

export default Blog

 