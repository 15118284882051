import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.div``;
export const MenuItemLinks = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 20px;
  text-decoration: none;
  color: #f0eeee;
  text-transform: uppercase;
  font-size: 1.4rem;

  &:visited {
    background-color: #252831;
    color: #f0eeee;
  }
  &:hover {
    transform: scaleY(1);
    width: 100%;
    background-color: #252831;
    border-left: 4px solid #632ce4;
  }
`;
export const MenuItems = styled.li`
   padding: 1rem 0 1.25rem; 
   display: flex;
   align-items: stretch;
`;
export const SidebarNav = styled.nav`
  display: flex;
  flex: 0 0 15%;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: stretch; */
  transition: 350ms;
  background: #0ea5e9;
  height: 100%;
  /* position: absolute; */
`;
