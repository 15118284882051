import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

// const theme = {
//   primary: {
//     background: "#0ea5e9",
//     // color: "#FFFFFF",
//     hover: "#F1CB00",
//     hoverColor: "#3b3b3b",
//   },
//   secondary: {
//     background: "#4D4100",
//     // color: "#FFFFFF",
//     hover: "#F1CB00",
//     hoverColor: "#3b3b3b",
//   },
//   default: {
//     background: "none",
//     // color: "#FFFFFF",
//     hover: "#F1CB00",
//     hoverColor: "#F1CB00",
//   },
// };

// export const Button = styled(Link)`
//   outline: none;
//   transition: all 0.2s ease;
//   text-decoration: none;
//   text-transform: uppercase;

//   font-weight: 500;
//   font-size: 1.8rem;
//   border-radius: 4px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   padding: 1.5rem 2rem;
//   transition: all 0.2s ease;

//   /* color: ${(props) => theme[props.theme].color}; */
//   background: ${(props) => theme[props.theme].background};

//   &:hover {
//     transition: all 0.2s ease;
//     opacity: 0.9;
//     transform: scale(0.98);
//     /* color: ${(props) => theme[props.theme].hoverColor}}; */
//     background: ${(props) => theme[props.theme].hover};
//   }
// `;

// export const UserIcon = styled(FaIcons.FaUserCircle)`
//   color: var(--color-primary);
//   width: 20px;
//   height: 20px;
//   margin-left: -1.2rem;
// `;

export const Button = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid var(--color-primary);
  font-weight: 400;
  font-size: 1.8rem;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  padding: 1rem 3rem;
  transition: all 0.2s ease;
  cursor: pointer;
  color: ${({ variant }) =>
    variant === "outline" ? "var(--color-primary)" : "#fff"};
  background: ${({ variant }) => (variant === "outline" ? "none" : "#0ea5e9")};

  &:hover {
    transition: all 0.2s ease;
    /* opacity: 0.9; */
    /* transform: scale(0.98); */
    color: ${({ variant }) => (variant === "outline" ? "#F1CB00" : "#3b3b3b")};
    background: ${({ variant }) =>
      variant === "outline" ? "none" : "#F1CB00"};
    /* border: ${({ variant }) =>
      variant === "outline" ? "2px solid #F1CB00" : "none"}; */
    border-color: #f1cb00;
  }
`;

export const ButtonDefault = styled(Button)`
  background: none;
  color: #fff;
  border: solid 2px #fff;
  display: flex;
  gap: 1.2rem;
  padding: 1.2rem 2rem;

  &:hover {
    border: solid 2px #f1cb00;
    background: none;
    color: #f1cb00;
  }
`;
