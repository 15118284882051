import React from "react";

import About from "./About";
import Activity from "./Activity";
import DonationForm from "./DonationForm";
import Testimonial from "./Testimonial";
import Hero from "./Hero";
import ViewBlog from "./ViewBlog";
import { HomeContainer } from "./Home.styled";
import BlogContainer from "./BlogContainer";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <HomeContainer>
        <Hero />
        <About />
        <Activity />
        <DonationForm />
        <BlogContainer />
        {/* <ViewBlog /> */}
        <Testimonial />
        <Footer />
      </HomeContainer>
    </>
  );
};

export default Home;
