import React from "react";
import {
  ImageContainer,
  NameContainer,
  QuoteContainer,
  StyledCardTestimonial,
  TextContainer,
} from "./Home.styled";
import * as FaIcons from "react-icons/fa";

function CardTestimonial({ item: { id, image, body, name, title } }) {
  return (
    <StyledCardTestimonial>
      <ImageContainer>
        <img src={image} alt="" />
      </ImageContainer>
      <TextContainer>
        <p>{body}</p>

        <NameContainer>
          <h2>{name}</h2>
          <span>{title}</span>
        </NameContainer>
      </TextContainer>
      <QuoteContainer>
        <FaIcons.FaQuoteLeft />
      </QuoteContainer>
    </StyledCardTestimonial>
  );
}

export default CardTestimonial;
