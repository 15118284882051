import React, { useState } from "react";
import {
  ActivityContainer,
  ActivityTabs,
  StyledCardActivity,
} from "./Home.styled";

const CardActivity = (props) => {
  console.log("prssssssssssssssops", props);
  const [{ items, activeIndex }, setActiveIndex] = useState({
    items: props.items,
    activeIndex: 0,
  });

  const showCard = (newIndex) => {
    if (newIndex === -1) {
      setActiveIndex((s) => ({ ...s, activeIndex: items.length - 1 }));
      return;
    }
    if (newIndex === items.length) {
      setActiveIndex((s) => ({ ...s, activeIndex: 0 }));
      return;
    }
    setActiveIndex((s) => ({ ...s, activeIndex: newIndex }));
  };

  return (
    <>
      <ActivityContainer>
        <ActivityTabs>
          {items.map((item, index) => (
            <span
              key={index}
              onClick={() => showCard(index)}
              // active={activeIndex === item.index ? "active" : undefined}
            >
              {item.title}
            </span>
          ))}
        </ActivityTabs>
        <StyledCardActivity>
          <div>
            <img
              src={require(`../../assets/images/${items[activeIndex].image}`)}
              alt=""
            />
          </div>
          {/* <h2>{items[activeIndex].title}</h2> */}
          <p>{items[activeIndex].body}</p>
        </StyledCardActivity>
      </ActivityContainer>
    </>
  );
};

export default CardActivity;
