import { createReducer, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPostsGraphql } from "../../services";

export const initialState = {
  loading: false,
  hasErrors: false,
  posts: [],
};

export const PostSlice = createSlice({
  name: "Posts",
  initialState,
  reducers: {
    getPosts: (state) => {
      state.loading = true;
    },
    getPostsSuccess: (state, { payload }) => {
      state.posts = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getPostsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

//actions generated by the slices
export const { getPosts, getPostsSuccess, getPostsFailure } = PostSlice.actions;

//A selector
export const PostsSelector = (state) => state.posts;

//Asynchronous Thunk Action
export function fetchPosts() {
  return async (dispatch) => {
    dispatch(getPosts());

    try {
      const response = await getPostsGraphql();

      dispatch(getPostsSuccess(response));
    } catch (error) {
      dispatch(getPostsFailure());
    }
  };
}
export default PostSlice.reducer;
