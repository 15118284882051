import React, { useEffect } from "react";
import LogoImage from "../../assets/logo2.png";
import Categories from "../Categories/Categories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  CategoriesSelector,
} from "../../store/reducers/categoriesReducers";
import "./BlogNavbar.css";

const BlogNavbar = () => {

  const dispatch = useDispatch();
  const { categories, loading, hasErrors } = useSelector(CategoriesSelector);
  const LoadingCategoriesJsx = () => <div>Loading...</div>;
  const ErrorLoadingCategoriesJsx = () => (
    <div className="mx-auto alert-danger">Error!</div>
  );
  useEffect(async () => {
    dispatch(fetchCategories());
  }, [dispatch]);
  if (loading) return LoadingCategoriesJsx();
  if (hasErrors) return ErrorLoadingCategoriesJsx();

  return (
    <div class="container nav">
      <div class="info">
        <img src={LogoImage} />
        {/* On desktop control with left and right keys */}
      </div>
      <header class="nav-header">
        <span class="logo">
          category
        </span>
        <nav class="middle scroll">
          {
            categories.map((category)=>(
            
            <span class="item" key={category.slug}>{category.name}</span>
            ))
          }
         <span class="item">Home</span>
          
        </nav>
      </header>

      
    </div>
  );
};

export default BlogNavbar;