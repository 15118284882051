import * as FaIcons from "react-icons/fa";
import member1 from "../../assets/images/member-1.jpg";
import member2 from "../../assets/images/member-2.jpg";

export const aboutContent = [
  {
    id: 1,
    icon: <FaIcons.FaLightbulb />,
    title: "Notre objectif",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    icon: <FaIcons.FaHandHoldingHeart />,
    title: "Notre vision",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 3,
    icon: <FaIcons.FaHandshake />,
    title: "Notre philosophie",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 4,
    icon: <FaIcons.FaPeopleCarry />,
    title: "Notre équipe",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const activityData = [
  {
    id: 1,
    image: "activity-qps.jpg",
    title: "qps",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 2,
    image: "activity-feo.jpg",
    title: "feo",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
  },
  {
    id: 3,
    image: "activity-aps.jpg",
    title: "aps",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. um dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
  },
  {
    id: 4,
    image: "activity-qls.jpg",
    title: "qls",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
  },
];
export default activityData;

export const testimonialContent = [
  {
    id: 1,
    image: member1,
    body: "Un nouveau paradygme basé sur la transcendance dans une société jeune ayant la capacité d’innover.",
    name: "Stanley SAINVIL,",
    title: "Secrétaire Général",
  },
  {
    id: 2,
    image: member2,
    body: "MhatriX NG une experience d'une vie. Se former pour former les autres est la meilleure façon de construire un monde égalitaire. ",
    name: "Eliode BAYARD",
    title: "CEA, présidente",
  },
];

export const BlogCardData = [
  {
    id: 1,
    title: "\"QUE LA LUMIÈRE SOIT\", une lueur d'espoir qui s'allume!",
    tag: "Et si l'on faisait en sorte que les efforts des professeurs soient considérés à leur juste valeur?",
    category: "éducation",
    date: "18-01-2020",
    author: "Ded-Fegens BAPTISTE",
    authorFormation: "Energy Engineering Student, UI / UX Designer",
    avatar: "Avatar-1.png",
  },
  {
    id: 2,
    title: "\"QUE LA LUMIÈRE SOIT\", une lueur d'espoir qui s'allume!",
    tag: "Et si l'on faisait en sorte que les efforts des professeurs soient considérés à leur juste valeur?",
    category: "éducation",
    date: "18-01-2020",
    author: "Ded-Fegens BAPTISTE",
    authorFormation: "Energy Engineering Student, UI / UX Designer",
    avatar: "Avatar-1.png",
  },
];
