import React from "react";
import styled from "styled-components";
import "./search.css";
import { FaSearch } from "react-icons/fa";
const Wrapper = styled.div``;
const search = styled.div``;

const Searchbar = () => {
  return (
    <div>
      <div className="wrapper">
        <div className="search">
          <input
            id="search"
            type="search"
            placeholder="Search a color by name..."
            autoComplete="off"
          />
          <FaSearch />
          {/* <i class="fas fa-search"></i> */}
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
