/**
 * The Grid components accepts a handful of properties. Let’s go over each one of them.

row – Lets the component know this is going to be a row. This the default value.

column – Converts the component from a row to a column. This is useful to add inside your row component.

expanded – This is property is for Grid components that are rows. It gives the row full width of the parent element.

alignItems – Defines the CSS property align-items. This property works with the row type component.

justify – Defines the CSS property justify-content. This property works with the row type component.

sm – Defines the size of the column from mobile to desktop; if not overriden.

md – Defines the size of the column for tablet viewport and wider; if not overriden.

lg – Defines the size of the column for desktop viewport and wider; if not overriden.
 * 
 */
import React from 'react'
import styles from './Grid.module.css'
const Grid = props => {
    const {
      alignItems,
      children,
      column,
      expanded,
      justify,
      lg,
      md,
      row,
      sm
    } = props;
  
    const isRow = row || !column;
  
    const classes =
      (!isRow ? styles.column : styles.row) +
      // Row styling
      (isRow && expanded ? ` ${styles.expanded}` : "") +
      (isRow && justify ? ` ${styles[justify]}` : "") +
      (isRow && alignItems ? ` ${styles["align-" + alignItems]}` : "") +
      // Column styling
      (!isRow && sm ? ` ${styles["sm-" + sm]}` : "") +
      (!isRow && md ? ` ${styles["md-" + md]}` : "") +
      (!isRow && lg ? ` ${styles["lg-" + lg]}` : "");
  
    return <div className={classes}>{children}</div>;
  };
  
  export default Grid;