import styled from "styled-components";

export const Flex = styled.div`
  /* height: 70vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto;
  /* flex-direction: column; */
`;
