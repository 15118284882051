import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import styled from 'styled-components/'
// import MemberHeaderNav from '../components/Navbar/MemberHeaderNav';
import MemberHeaderNav from '../components/Nav/MemberHeaderNav';
import Sidebar from '../components/Sidebar/Sidebar'
import Dashboard from '../Container/Dashboard';
import Grid from '../components/Grid/Grid';
const DashboardWrapper = styled.div`
  max-width: 120rem;
  margin: 8rem auto;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  background-color: #f4f2f2;
`;
const MemberLayout = (props) => {
  return (
    <DashboardWrapper>
      <Grid row={true} expanded> 
        <Grid column={true} sm={12} md={12}>
        <MemberHeaderNav />
        </Grid>
      </Grid>
      <Grid row={true} expanded>
      <Grid column={true} sm={12} md={3} >  
         <Sidebar />
      </Grid>
      <Grid column={true} sm={12} md={9}>
        <Dashboard>
          <Outlet />
        </Dashboard>
      </Grid>
    
    </Grid>
    </DashboardWrapper>
    
  )
}

export default MemberLayout